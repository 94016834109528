import { Tooltip } from '@mui/material'
import * as React from 'react'
import { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import ButtonConfirm from '@components/CalendarAccordion/ButtonConfirm'
import { Children } from '@components/GuestAccordion/Children'
import { Pets } from '@components/GuestAccordion/Pets'
import { Form } from '../../forms/Form'
import {
  defaultValuesForGuestsWithKidsAndPetsForm,
  GuestsWithKidsAndPetsFormValues,
  useGuestsWithKidsAndPetsForm,
} from '../../forms/guests/useGuestsWithKidsAndPetsForm'
import { getValidGuestNumberOrNull } from '../../helpers'
import { useAccordion } from '../../hooks/useAccordion'
import { useGuestsAccordion } from '../../hooks/useGuestsAccordion'
import { useQueryParams } from '../../hooks/useQueryParams'
import { useBookingFormStore } from '../../providers/BookingFormStoreProvider'
import { AccordionEnum, IChild, IPet } from '../../store/data'
import Accordion from '../Accordion'
import AccordionTitle from '../AccordionTitle'
import Guests from './Guests'

const GuestAccordion: React.FunctionComponent = () => {
  const { routerQueryParams } = useQueryParams()

  const accordion = useBookingFormStore((state) => state.accordion)
  const guest = useBookingFormStore((state) => state.guest)
  const children = useBookingFormStore((state) => state.children)
  const childrenCount = useBookingFormStore((state) => state.childrenCount)
  const pets = useBookingFormStore((state) => state.pets)
  const petsCount = useBookingFormStore((state) => state.petsCount)
  const isFetching = useBookingFormStore((state) => state.isFetching)
  const destination = useBookingFormStore((state) => state.destination)
  const appInitialized = useBookingFormStore((state) => state.appInitialized)
  const changeGuest = useBookingFormStore((state) => state.changeGuest)
  const isDeletingQueryParameter = useBookingFormStore((state) => state.isDeletingQueryParameter)
  const saveChildren = useBookingFormStore((state) => state.saveChildren)
  const savePets = useBookingFormStore((state) => state.savePets)
  const changeAccordion = useBookingFormStore((state) => state.changeAccordion)
  const changePetsCount = useBookingFormStore((state) => state.changePetsCount)
  const changeChildrenCount = useBookingFormStore((state) => state.changeChildrenCount)

  const isOpen = accordion === AccordionEnum.GUESTS
  const isLoading = isFetching && !!routerQueryParams.guest
  const isActive = isLoading || !!guest
  const isDisabled = !destination && !guest

  const { onChangeGuest } = useGuestsAccordion()
  const { onChangeAccordion, resolveAccordionFromRouteQuery } = useAccordion()

  const guestsWithKidsAndPetsForm = useGuestsWithKidsAndPetsForm(guest, children, pets)
  const { getValues, handleSubmit, reset, watch } = guestsWithKidsAndPetsForm

  const guestCount = watch('guest')

  useEffect(() => {
    const guestFromQueryParameters = getValidGuestNumberOrNull(routerQueryParams.guest)

    if (
      appInitialized &&
      !isDeletingQueryParameter &&
      !guest &&
      guestFromQueryParameters !== null
    ) {
      // Covers the situation where the booking form is loading with no guests selected, but a customer selects a guest in the meantime.
      changeGuest(guestFromQueryParameters)
    }
  }, [appInitialized, guest, changeGuest, routerQueryParams.guest, isDeletingQueryParameter])

  // when loading from query parameters on app init, set the form values
  useEffect(() => {
    const allFormValues = getValues()

    console.log('children: ', children)

    reset({
      ...allFormValues,
      guest: guest || 0,
      kidsAge: children,
      petsSpecies: pets,
    })
  }, [guest, getValues, reset, pets, children])

  const onClear = async () => {
    changeChildrenCount(0)
    changePetsCount(0)
    reset(defaultValuesForGuestsWithKidsAndPetsForm)
    await onChangeGuest(null)
  }

  const onSubmit = (data: GuestsWithKidsAndPetsFormValues) => {
    const { guest: guestFromForm, kidsAge = [], petsSpecies = [] } = data
    // store info about pets
    savePets(petsSpecies as IPet[])
    // store info about children
    saveChildren(kidsAge as IChild[])
    // store info about guests
    onChangeGuest(guestFromForm || null)

    // change accordion
    const accordion = resolveAccordionFromRouteQuery()
    changeAccordion(accordion)
  }

  const guestTitle = () => {
    const guestCounter = parseInt(String(guestCount || guest || 0))

    return (
      <>
        <FormattedMessage
          defaultMessage="{count, plural, =0 {Guests} one {# Guest} other {# Guests}}"
          description="Guest accordion title"
          values={{ count: guestCounter }}
        />
        {(childrenCount > 0 || petsCount > 0) && (
          <>
            {' '}
            (
            <FormattedMessage
              defaultMessage="{count, plural, =0 {# kids} one {# kid} other {# kids}}"
              description="Children plural count selector description"
              values={{ count: childrenCount }}
            />
            {childrenCount > 0 && petsCount > 0 && ', '}
            {petsCount > 0 && (
              <>
                {' '}
                <FormattedMessage
                  defaultMessage="{count, plural, =0 {# pets} one {# pet} other {# pets}}"
                  description="Pets plural count selector description"
                  values={{ count: petsCount }}
                />
              </>
            )}
            )
          </>
        )}
      </>
    )
  }

  return (
    <Form<GuestsWithKidsAndPetsFormValues> className="w-full" form={guestsWithKidsAndPetsForm}>
      <Accordion
        active={isActive}
        disabled={isDisabled}
        isLoading={isLoading}
        onClear={onClear}
        onClose={() => onChangeAccordion(null)}
        onOpen={() => onChangeAccordion(AccordionEnum.GUESTS)}
        open={isOpen}
        title={<AccordionTitle isLoading={isLoading}>{guestTitle()}</AccordionTitle>}
      >
        <div className="grid grid-cols-1 md:grid-cols-[14rem_1fr] gap-x-4 items-center">
          <Guests />
          <Children />
          <Pets />
        </div>
        <div className="border-t-2 border-t-card-default my-4" />
        <div className="flex items-center justify-end">
          <Tooltip
            title={
              guestCount === 0 ? (
                <FormattedMessage
                  defaultMessage="Guests are required"
                  description="Guests are required in Guests form"
                />
              ) : null
            }
          >
            <div>
              <ButtonConfirm disabled={guestCount === 0} onConfirm={handleSubmit(onSubmit)} />
            </div>
          </Tooltip>
        </div>
      </Accordion>
    </Form>
  )
}

export default GuestAccordion
