import { AxiosResponse } from 'axios'
import { useEffect } from 'react'
import { getDestinations } from '../api'
import { getBookingConfigDestinationQueryParameter, getLang } from '../helpers'
import { useBookingFormStore } from '../providers/BookingFormStoreProvider'
import { handleApiErrorAndShowNotification } from '../store/bookingFormStore'
import { IDestination, ILanguage } from '../store/data'
import { useDestinationsAccordion } from './useDestinationsAccordion'
import { useGuestsAccordion } from './useGuestsAccordion'
import { useHotelAccordion } from './useHotelAccordion'
import { DESTINATION_PARAM, GUEST_PARAM, useQueryParams } from './useQueryParams'
import { useRoomTypeAccordion } from './useRoomTypeAccordion'
import { useVisitTypeCategoriesAccordion } from './useVisitTypeCategoriesAccordion'

export const useLoadBookingForm = () => {
  const { addQueryParameter, routerQueryParams } = useQueryParams()

  const { onInitGuestValidation } = useGuestsAccordion()
  const { onInitDestinationValidation } = useDestinationsAccordion()
  const { onInitHotelValidation } = useHotelAccordion()
  const { onInitVisitTypeValidation } = useVisitTypeCategoriesAccordion()
  const { onInitRoomTypeValidation } = useRoomTypeAccordion()

  const language = useBookingFormStore((state) => state.language)
  const getBookingConfiguration = useBookingFormStore((state) => state.getBookingConfiguration)
  const setDestinations = useBookingFormStore((state) => state.setDestinations)
  const setAppInitialized = useBookingFormStore((state) => state.setAppInitialized)
  const appInitialized = useBookingFormStore((state) => state.appInitialized)
  const setAppInitializedAndValidated = useBookingFormStore(
    (state) => state.setAppInitializedAndValidated
  )
  const appInitializedAndValidated = useBookingFormStore(
    (state) => state.appInitializedAndValidated
  )
  const setIsFetching = useBookingFormStore((state) => state.setIsFetching)
  const addNotification = useBookingFormStore((state) => state.addNotification)
  const validationState = useBookingFormStore((state) => state.validationState)
  const changeValidationState = useBookingFormStore((state) => state.changeValidationState)
  const roomType = useBookingFormStore((state) => state.roomType)
  const guest = useBookingFormStore((state) => state.guest)
  const hotel = useBookingFormStore((state) => state.hotel)
  const destination = useBookingFormStore((state) => state.destination)
  const destinations = useBookingFormStore((state) => state.destinations)
  const visitType = useBookingFormStore((state) => state.visitType)
  const loadHotels = useBookingFormStore((state) => state.loadHotels)
  const loadVisitCategories = useBookingFormStore((state) => state.loadVisitCategories)

  // init language and check booking config while app initialization
  useEffect(() => {
    const fetchInitialBookingConfiguration = async (initialLanguage: ILanguage) => {
      try {
        setIsFetching(true)

        let destinationsData = []
        let destinationsStatus = 200

        if (destinations.length) {
          destinationsData = destinations
        } else {
          const destinationsResponse: AxiosResponse<IDestination[]> = await getDestinations()
          destinationsData = destinationsResponse.data
          destinationsStatus = destinationsResponse.status
        }

        if (destinationsStatus === 200) {
          setDestinations(destinationsData)

          const bookingConfigDestinationName = getBookingConfigDestinationQueryParameter(
            routerQueryParams?.destination || null,
            initialLanguage,
            destinationsData
          )

          await getBookingConfiguration(
            {
              ...routerQueryParams,
              destination: bookingConfigDestinationName,
              lang: initialLanguage,
            },
            // Guests can be set during app initialization even when the guest parameter is empty, but beds are filled.
            async (guest: number | null, destination: IDestination | null) => {
              if (guest) {
                await addQueryParameter(GUEST_PARAM, `${guest}`)
              }
              if (destination) {
                const destinationName = getLang(destination.name, language)
                await addQueryParameter(DESTINATION_PARAM, destinationName)
              }
            }
          )
        }
      } catch (error: any) {
        handleApiErrorAndShowNotification(error, addNotification)
      } finally {
        setIsFetching(false)
      }
    }

    if (!appInitialized) {
      fetchInitialBookingConfiguration(language as ILanguage).then(() => {
        setAppInitialized(true)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, appInitialized])

  useEffect(() => {
    if (appInitialized && !appInitializedAndValidated) {
      switch (validationState) {
        case 'DESTINATIONS': {
          onInitDestinationValidation(destination)
          changeValidationState('GUESTS')
          break
        }
        case 'GUESTS': {
          onInitGuestValidation(guest)
          changeValidationState('HOTEL')
          break
        }
        case 'HOTEL': {
          onInitHotelValidation(hotel).then(() => changeValidationState('VISIT_TYPE'))
          break
        }
        case 'VISIT_TYPE': {
          onInitVisitTypeValidation(visitType) // TODO maybe process promise similar to onInitHotelValidation
          changeValidationState('ROOM_TYPE')
          break
        }
        case 'ROOM_TYPE': {
          onInitRoomTypeValidation(roomType)
          changeValidationState(null)
          break
        }
        case null: {
          setAppInitializedAndValidated(true)
          break
        }
        default:
      }
    }
  }, [
    appInitialized,
    appInitializedAndValidated,
    setAppInitializedAndValidated,
    validationState,
    destination,
    guest,
    visitType,
    hotel,
    roomType,
  ])

  useEffect(() => {
    const fetchVisitCategories = async () => {
      await loadVisitCategories({
        destination_id: destination?.id || null,
      })
    }

    if (appInitialized && appInitializedAndValidated) {
      fetchVisitCategories()
    }
  }, [appInitialized, appInitializedAndValidated, loadVisitCategories, destination?.id])

  useEffect(() => {
    const fetchHotels = async () => {
      await loadHotels({
        destination_id: destination?.id || null,
        include_room_types: false,
      })
    }

    if (appInitialized && appInitializedAndValidated) {
      fetchHotels()
    }
  }, [loadHotels, appInitialized, appInitializedAndValidated, destination?.id])
}
